<template>
  <b-container
    class="bg-white"
  >
    <b-row>
      <b-col>
        <h1 class="mt-4">
          Aira Report Generator
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          v-if="errorMessage.length"
        >
          <b-alert
            v-for="error of errorMessage"
            :key="error"
            show
            variant="danger"
          >
            Error: {{ error }}. Please escalate to engineering for troubleshooting.
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form
          inline
          class="inline-form mb-4"
          @submit.prevent="onSubmit"
        >
          <b-form-input
            id="inline-form-input-serviceId"
            v-model="serviceId"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
            min="0"
            placeholder="Service ID"
            @keyup.enter.native="searchServiceId()"
          />
          <b-button
            variant="primary"
            @click="searchServiceId()"
          >
            Search
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row
      v-if="!showReport && reportData != null"
      class="text-left"
    >
      <b-col>
        <h2 style="text-align: center;">
          Don't have permissions to view this report.
        </h2>
      </b-col>
    </b-row>
    <div v-if="showReport">
      <b-row
       
        class="text-left"
      >
        <b-col>
          <h2 style="text-align: center;">
            {{ isASLReport ? "ASL Service " : "BLV Service " }}
          </h2>
          <h3>Basic Information</h3>
          <ul class="ml-4">
            <li><b>Service ID:</b> {{ reportData.serviceId }}</li>
            <li>
              <b>Explorer ID:</b> <a
                :href="mgmtUserLink(reportData.userId)"
                target="_blank"
              >
                {{ reportData.userId }}
              </a>
            </li>
            <li><b>Explorer name:</b> {{ reportData.userName }}</li>
            <li v-if="!hasParticipantInfo">
              <b>Agent ID:</b> <a
                :href="mgmtUserLink(reportData.agentId)"
                target="_blank"
              >
                {{ reportData.agentId }}
              </a>
            </li>
            <li v-if="!hasParticipantInfo">
              <b>Agent name:</b> {{ reportData.agentName }}
            </li>
            <li>
              <b>Explorer last known location:</b>
              <template v-if="lastLocation.id">
                {{ lastLocation.address1 }} {{ lastLocation.city }}, {{ lastLocation.state }} {{ lastLocation.zip }}
              </template>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col v-if="hasParticipantInfo">
          <h3>Agents Information</h3>
          <ul class="ml-4">
            <div
              v-for="participant in reportData.participants"
              :key="participant.participantId"
            >
              <li v-if="(participant.participantType === 'WHISPER' && showObserverInfo) || participant.participantType === 'AGENT'">
                <b>{{ `${participant.participantType === "WHISPER" ? 'Observer ': ''}Agent ID:` }}</b> <a
                  :href="mgmtUserLink(participant.participantId)"
                  target="_blank"
                >
                  {{ participant.participantId }}
                </a>
                <br>
                <b>Agent name:</b> {{ participant.participantName }}
                <br>
                <b>Joined at:</b> {{ participant.joinedAt && isoStringTimes( participant.joinedAt) }}
                <br>
                <b>Left at:</b> {{ participant.leftAt && isoStringTimes( participant.leftAt) }}
              </li>
            </div>
          </ul>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Client Versions</h3>
          <ul class="ml-4">
            <li><b>Explorer Version:</b> {{ reportData.userClientVersion }}</li>
            <li><b>Agent Version:</b> {{ reportData.agentClientVersion }}</li>
          </ul>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Session</h3>
          <ul class="ml-4">
            <li><b>Service status:</b> {{ reportData.status }}</li>
            <li><b>Source:</b> {{ reportData.source }}</li>
            <li><b>Test call:</b> {{ reportData.testCall }}</li>
            <li><b>Cannot talk:</b> {{ reportData.cannotTalk }}</li>
            <li><b>Reconnect:</b> {{ reportData.reconnect }}</li>
            <li><b>Web RTC:</b> {{ reportData.webrtcType }}</li>
            <li><b>Did not connect:</b> {{ reportData.didNotConnect }}</li>
            <li><b>Usage calculated:</b> {{ reportData.usageCalculated }}</li>
            <li><b>Preemptive message:</b> {{ reportData.preemptiveMessage }}</li>
            <li><b>Request time: </b>{{ isoSecondTimes(reportData.requestTimestamp) }}</li>
            <li><b>Pick time: </b>{{ isoSecondTimes(reportData.pickTimestamp) }}</li>
            <li><b>Start time: </b>{{ isoSecondTimes(reportData.startTimestamp) }}</li>
            <li><b>End time: </b>{{ isoSecondTimes(reportData.endTimestamp) }}</li>
          </ul>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Photos</h3>
          <div
            v-if="photoDeleted.length"
          >
            <b-alert
              v-for="deleted of photoDeleted"
              :key="deleted.photoId"
              dismissible
              show
              variant="success"
            >
              Photo {{ deleted.photoId }} has been deleted!
            </b-alert>
          </div>
          <b-table-simple bordered>
            <b-tbody
              v-for="(photo, index) of reportData.photos"
              :key="photo.id"
            >
              <b-tr>
                <b-td>
                  <b>Photo:</b> {{ photo.id }}
                  <ul class="ml-4">
                    <li><b>Title:</b> {{ photo.title }}</li>
                    <li><b>Description:</b> {{ photo.description }}</li>
                    <li><b>Favorite:</b> {{ photo.favorite }}</li>
                    <li><b>Temporary:</b> {{ photo.temporary }}</li>
                    <li>
                      <b>Shares:</b>
                      <div
                        v-for="shares of photo.shares"
                        :key="shares.id"
                      >
                        <ul class="ml-4">
                          <li><b>Explorer ID:</b> {{ shares.userId }}</li>
                          <li><b>Agent ID:</b> {{ shares.agentId }}</li>
                          <li><b>Type:</b> {{ shares.type }}</li>
                          <li><b>Status:</b> {{ shares.status }}</li>
                          <li><b>Email:</b> {{ shares.email }}</li>
                          <li><b>Created:</b> {{ shares.createdDate }}</li>
                        </ul>
                      </div>
                    </li>
                    <li><b>Sent to Explorer:</b> {{ photo.sentToUser }}</li>
                    <li><b>Created:</b> {{ photo.createdDate && isoStringTimes(photo.createdDate) }}</li>
                    <li>
                      <b>Snapshot Url:</b> <a
                        :href="photo.url"
                        target="_blank"
                      >
                        Image {{ photo.id }}</a>
                      <b-button
                        class="ml-2"
                        variant="primary"
                        @click="deletePhoto(photo.id, index)"
                      >
                        Delete Photo
                      </b-button>
                    </li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Access Details</h3>
          <b-table-simple bordered>
            <b-tbody
              v-for="accesses of reportData.serviceRequestToAccesses"
              :key="accesses.id"
            >
              <b-tr>
                <b-td>
                  <b>Request: {{ accesses.id }}</b>
                  <ul class="ml-4">
                    <li>
                      <b>Account:</b>
                      <ul class="ml-4">
                        <li>
                          <b>ID:</b> <a
                            :href="mgmtAccountLink(accesses.access.account.id)"
                            target="_blank"
                          >{{ accesses.access.account.id }}</a>
                        </li>
                        <li><b>Name:</b> {{ accesses.access.account.name }}</li>
                        <li><b>Code:</b> {{ accesses.access.account.accountCode }}</li>
                        <li><b>Type:</b> {{ accesses.access.account.accountType }}</li>
                      </ul>
                    </li>
                    <li>
                      <b>Access:</b>
                      <ul class="ml-4">
                        <li><b>Name:</b> {{ accesses.access.name }}</li>
                        <li><b>Key:</b> {{ accesses.access.key }}</li>
                        <li><b>Type:</b> {{ accesses.access.type }}</li>
                        <li><b>Class:</b> {{ accesses.access.class }}</li>
                      </ul>
                    </li>
                    <li><b>Agent verified:</b> {{ accesses.agentVerified }}</li>
                    <li><b>Enabled:</b> {{ accesses.enabled }}</li>
                    <li><b>Initiated user type:</b> {{ accesses.initiatedUserType }}</li>
                    <li><b>Initiated user ID:</b> {{ accesses.initiatedUserId }}</li>
                    <li><b>Start time:</b> {{ accesses.startTime && isoStringTimes(accesses.startTime) }}</li>
                    <li><b>End time:</b> {{ accesses.endTime && isoStringTimes(accesses.endTime) }}</li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Customer Support Session</h3>
          <b-table-simple bordered>
            <b-tbody
              v-for="session of reportData.customerSupportSessions"
              :key="session.id"
            >
              <b-tr>
                <b-td>
                  <b>Session: {{ session.id }}</b>
                  <ul class="ml-4">
                    <li><b>Customer support ID:</b> {{ session.customerSupportId }}</li>
                    <li><b>Inbound phone:</b> {{ session.inboundPhone }}</li>
                    <li><b>Outbound phone:</b> {{ session.outboundPhone }}</li>
                    <li><b>Message:</b> {{ session.message }}</li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Aira Live Sessions</h3>
          <b-table-simple bordered>
            <b-tbody
              v-for="live of reportData.airaLiveSessions"
              :key="live.id"
            >
              <b-tr>
                <b-td>
                  <b>Live Session ID: {{ live.id }}</b>
                  <ul class="ml-4">
                    <li><b>Session Name:</b> {{ live.sessionName }}</li>
                    <li><b>Started at:</b> {{ live.startedAt && isoMillisecondTimes(live.startedAt) }}</li>
                    <li><b>Description:</b> {{ live.description }}</li>
                    <li><b>Passcode needed:</b> {{ live.needPassCode }}</li>
                    <li><b>Type:</b> {{ live.type }}</li>
                    <li><b>Participant count:</b> {{ live.participantCount }}</li>
                    <li><b>Status:</b> {{ live.status }}</li>
                    <li><b>Featured:</b> {{ live.featured }}</li>
                    <li><b>Room ID:</b> {{ live.roomId }}</li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Usage Consumption Snapshots</h3>
          <b-table-simple bordered>
            <b-tbody
              v-for="snapshot of reportData.usageSnapshots"
              :key="snapshot.id"
            >
              <b-tr>
                <b-td>
                  <b>Snapshot: {{ snapshot.id }}</b>
                  <ul class="ml-4">
                    <li><b>Usage value:</b> {{ snapshot.usageValue }} seconds</li>
                    <li><b>Consumed as:</b> {{ snapshot.consumedAs }}</li>
                    <li><b>Usage state ID:</b> {{ snapshot.usageStateId }}</li>
                    <li><b>Site ID:</b> {{ snapshot.siteId }}</li>
                    <li><b>Product ID:</b> {{ snapshot.productId }}</li>
                    <li><b>Service to access ID:</b> {{ snapshot.serviceToAccessId }}</li>
                    <li><b>Usage timestamp:</b> {{ snapshot.usageTimestamp && isoStringTimes(snapshot.usageTimestamp) }}</li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Dashboard Logs</h3>
          <ul class="ml-4">
            <li
              v-for="log of reportData.dashboardLogs"
              :key="log.s3Path"
            >
              <a
                :href="dashboardUrl(log.s3Path)"
                target="_blank"
              >{{ log.s3Path.split('/').pop() }}
              </a>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Recordings</h3>
          <ul class="ml-4">
            <li
              v-for="recording of reportData.webrtcRecordings"
              :key="recording.s3Path"
            >
              <div v-if="recording.s3Url">
                <a
                  :href="webrtcRecording(recording.s3Path)"
                  target="_blank"
                >
                  {{ recording.s3Path.split('/').pop() }}
                </a>
                {{ userTypeOrName(recording) }}
                <br>
                <audio
                  v-if="recording.s3Path.includes('audio')"
                  controls
                  preload="metadata"
                  :src="recording.s3Url"
                />
                <video
                  v-else
                  controls
                  height="240"
                  preload="metadata"
                  :src="recording.s3Url"
                  width="320"
                />
              </div>
              <div v-else>
                <a
                  :href="webrtcRecording(recording.s3Path)"
                  target="_blank"
                >
                  {{ recording.s3Path.split('/').pop() }}
                </a> {{ recordingCheck(recording.recorded) }}
                {{ userTypeOrName(recording) }}
              </div>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Comments</h3>
          <div
            v-if="reportData.userComment"
          >
            <b>User:</b>
            <ul class="ml-4">
              <li><b>Comment:</b> {{ reportData.userComment.comment }}</li>
              <li><b>Task success:</b> {{ reportData.userComment.taskSuccess }}</li>
              <li><b>Product task ID:</b> {{ reportData.userComment.productTaskId }}</li>
              <li><b>Modified Timestamp:</b> {{ reportData.userComment.modifiedTimestamp && isoStringTimes(reportData.userComment.modifiedTimestamp) }}</li>
              <li>
                <b>Tagged with:</b>
                <ul class="ml-4">
                  <li
                    v-for="userTag of reportData.userComment.taggedWith"
                    :key="userTag"
                  >
                    {{ userTag }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div
            v-if="reportData.agentComment"
          >
            <b>Agent:</b>
            <ul class="ml-4">
              <li><b>Comment:</b> {{ reportData.agentComment.comment }}</li>
              <li><b>Task success:</b> {{ reportData.agentComment.taskSuccess }}</li>
              <li><b>Product task ID:</b> {{ reportData.agentComment.productTaskId }}</li>
              <li>
                <b>Modified Timestamp:</b> {{ reportData.agentComment.modifiedTimestamp && isoStringTimes(reportData.agentComment.modifiedTimestamp) }}
              </li>
              <li>
                <b>Tagged with:</b>
                <ul class="ml-4">
                  <li
                    v-for="agentTag of reportData.agentComment.taggedWith"
                    :key="agentTag"
                  >
                    {{ agentTag }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>GPS Points</h3>
          <p>GPS Points: {{ reportData.coordinates.length }}</p>
          <GmapMap
            ref="googleMapRef"
            :center="{ lat:39.8097343, lng:-98.5556199 }"
            :zoom="3"
            :options="{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: true,
              rotateControl: true,
              fullscreenControl: true,
              disableDefaultUI: false
            }"
            map-type-id="terrain"
            class="w-100 mb-3"
            style="min-width: 500px; min-height: 400px"
          >
            <GmapMarker
              v-for="(m, index) in googleMapMarkers"
              :key="index"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :title="m.title"
              @click="centerGoogleMap(m.position)"
            />
          </GmapMap>
        </b-col>
      </b-row>
      <b-row
        v-if="reportData"
        class="text-left"
      >
        <b-col>
          <h3>Assignment Rules</h3>
          <b-table-simple bordered>
            <b-tbody
              v-for="rule of reportData.serviceRequestToAssignmentRules"
              :key="rule.target"
            >
              <b-tr>
                <b-td>
                  <ul class="ml-4">
                    <li><b>Assignment Rules:</b> {{ rule.assignmentRule }}</li>
                    <li><b>Target:</b> {{ rule.target }}</li>
                    <li><b>Tag:</b> {{ rule.tag }}</li>
                    <li><b>Assignment time:</b> {{ rule.createdTimestamp && isoStringTimes(rule.createdTimestamp) }}</li>
                  </ul>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import AiraError from '@/utils/AiraError';

import API from '../../api';

export default {
  name: 'Report',
  data() {
    return {
      pageName: 'report',
      serviceId: null,
      reportData: null,
      lastLocation: null,
      errorMessage: [],
      photoDeleted: [],
      googleMap: null,
      googleMapMarkers: [],
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'email',
      'observerAgents',
      'isASLAgent',
    ]),
    hasParticipantInfo() {
      return (this.reportData.participants && this.reportData.participants.length > 0);
    },
    showObserverInfo() {
      return this.observerAgents.includes(this.email);
    },
    isASLReport() {
      console.log('DEBUG isASLReport', this.reportData);
      return this.reportData && this.reportData.source === 'explorer-ui-asl';
    },
    showReport() {
      const isASL = this.reportData && this.reportData.source === 'explorer-ui-asl';
      const showReport = !(!isASL && this.isASLAgent);
      return this.reportData != null && showReport;
    },
    google: gmapApi,
  },
  mounted() {
    if (this.$route.query.serviceID != null) {
      this.serviceId = this.$route.query.serviceID;

      this.$nextTick(() => {
        // make sure serviceId is saved and populated, and on next tick we can access this object
        this.searchServiceId();
      });
    }
  },
  methods: {
    async searchServiceId() {
      // reinitialize to empty arrays on new search
      this.errorMessage = [];
      this.googleMapMarkers = [];
      this.photoDeleted = [];
      this.$store.commit('CLEAR_ERRORS');

      // clears previous report
      this.reportData = null;

      try {
        const response = await API.getReportData(this.serviceId);
        if (response) {
          this.reportData = response;
          this.lastLocation = response.userLastLocation;
        }
      } catch (error) {
        const err = new AiraError(error);
        this.errorMessage.push(err.errorMessage);
      }

      if (this.reportData.coordinates != null && this.reportData.coordinates.length > 0) {
        this.reportData.coordinates.forEach((coordinate) => {
          const position = { lat: coordinate.latitude, lng: coordinate.longitude };
          const title = coordinate.timestamp;
          const markerPayload = {
            position,
            title,
          };

          this.googleMapMarkers.push(markerPayload);
        });

        // ensure markers are pushed and the map is updated in next DOM cycle, before modifying map again
        this.$nextTick(() => {
          this.$gmapApiPromiseLazy().then(() => {
            console.debug('gmap api lazy load complete');
            const bounds = new window.google.maps.LatLngBounds();
            this.googleMapMarkers.forEach((marker) => {
              bounds.extend(marker.position);
            });
            this.$refs.googleMapRef.fitBounds(bounds);
          });
        });
      }
    },
    async deletePhoto(id, index) {
      try {
        await API.deletePhoto(id);
        this.photoDeleted.push({
          photoId: id,
        });
        this.reportData.photos.splice(index, 1);
      } catch (error) {
        const err = new AiraError(error);
        this.errorMessage.push(err.errorMessage);
      }
    },
    centerGoogleMap(position) {
      console.log('center on position', position);
      this.$refs.googleMapRef.panTo(position);
    },
    mgmtUserLink(id) {
      return `${process.env.VUE_APP_MGMT_URL}/users/${id}`;
    },
    mgmtAccountLink(id) {
      return `${process.env.VUE_APP_MGMT_URL}/accounts/${id}`;
    },
    isoSecondTimes(timestamp) {
      // this takes in numerical seconds
      return new Date(parseInt(timestamp, 10) * 1000);
    },
    isoStringTimes(timestamp) {
      // this takes in string '2020-11-30T02:03:51Z' or numerical in milliseconds
      return new Date(timestamp);
    },
    isoMillisecondTimes(timestamp) {
      // this takes in millisecond timestamp in string
      return new Date(parseInt(timestamp, 10));
    },
    dashboardUrl(s3Path) {
      return `https://s3.console.aws.amazon.com/s3/buckets/dashboard-logs.aira.io/${s3Path}`;
    },
    webrtcRecording(s3Path) {
      return `https://s3.console.aws.amazon.com/s3/buckets/records.aira.io/${s3Path}`;
    },
    webrtcTranscription(s3Path) {
      return `https://s3.console.aws.amazon.com/s3/buckets/transcriptions.aira.io/${s3Path}`;
    },
    recordingCheck(recorded) {
      if (recorded) {
        return '(recorded)';
      } else {
        return '(not recorded)';
      }
    },
    transcriptionCheck(transcribed) {
      if (transcribed) {
        return '(transcribed)';
      } else {
        return '(not transcribed)';
      }
    },
    // Get recording/transcription track user info to display with track links.
    userTypeOrName(recording) {
      if (recording) {
        if (recording.userTypeOrName) return (`(${recording.userTypeOrName})`);
      }
      return '(User N/A)';
    },
    onSubmit() {
      console.log('Submitted with enter!');
    },
  },
};
</script>

<style lang="scss">

</style>
